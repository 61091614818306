<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open" :close-on-click-modal="false"
                destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <x-divider title="基本信息"></x-divider>
            <el-form-item label="操作人" prop="caoZuoRen" >
                {{form.caoZuoRen}}
            </el-form-item>
            <el-form-item label="调价类型" prop="tiaoJiaLX">
                <x-selector-one v-model="form.tiaoJiaLX" dictType="T_TIAO_JIA_LX"/>
            </el-form-item>
            <el-form-item label="调价时间" prop="tiaoJiaSJ">
                <el-date-picker v-model="form.tiaoJiaSJ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="被修改订单" prop="beiXiuGaiDingDan" v-if="!isChuShiRuKu">
                <el-input v-model="form.beiXiuGaiDingDan" size="small" maxlength="64" show-word-limit/>
            </el-form-item>
            <el-form-item label="备注" prop="beiZhu" >
                <el-input v-model="form.beiZhu" size="small" maxlength="64" show-word-limit/>
            </el-form-item>
            <x-divider title="调价信息明细"></x-divider>

            <x-selector
                    placement="center"
                    selector="ShangPinXXSelector"
                    @selected="handleClSelect"
                    v-if="form.tiaoJiaLX == tjlx.CSHRK.key"
                    code-key="id"
                    selection
                    width="1000px">
                <el-button size="mini" type="success" round >添加商品</el-button>
            </x-selector>
            <x-selector
                    placement="center"
                    selector="CaiGouDanXiangQingSelector"
                    @selected="handleClSelectCG"
                    :param="{'caiGouDanID': form.beiXiuGaiDingDan}"
                    v-if="form.tiaoJiaLX == tjlx.CGD.key && form.beiXiuGaiDingDan != ''"
                    code-key="id"
                    selection
                    width="1000px">
                <el-button size="mini" type="success" round >添加商品</el-button>
            </x-selector>
            <x-selector
                    placement="center"
                    selector="RuKuMXSelector"
                    @selected="handleClSelectRK"
                    :param="{'ruKuDanID': form.beiXiuGaiDingDan}"
                    v-if="form.tiaoJiaLX == tjlx.QTRK.key && form.beiXiuGaiDingDan != ''"
                    code-key="id"
                    selection
                    width="1000px">
                <el-button size="mini" type="success" round >添加商品</el-button>
            </x-selector>

            <x-row :rows="form.tiaoJiaXinXiMXs" :rowModel="tiaoJiaXinXiMXsModel" no-add-btn>
                <x-row-cell title="调价商品Id" width="100" read="tiaoJiaShangPinId"/>
                <x-row-cell title="调价商品名称" width="100" read="tiaoJiaShangPinMC">
                </x-row-cell>
                <x-row-cell title="单位" width="100" read="danWei">
                </x-row-cell>
                <x-row-cell title="规格" width="100" read="guiGe">
                </x-row-cell>
                <x-row-cell title="货号" width="100" read="huoHao">
                </x-row-cell>
                <x-row-cell title="条码" width="100" read="tiaoMa">
                </x-row-cell>
                <x-row-cell title="原进价" width="100" prop="yuanJinJia">
                    <el-input slot-scope="{row}" v-model="row.yuanJinJia" size="small" disabled maxlength="18"/>
                </x-row-cell>
                <x-row-cell title="现进价" width="100" prop="xianJinJia">
                    <el-input slot-scope="{row}" v-model="row.xianJinJia" size="small" maxlength="18"/>
                </x-row-cell>
                <!--<x-row-cell v-if="!isChuShiRuKu" title="已入库数量" width="100" read="yiRuKuSL"></x-row-cell>-->
                <x-row-cell title="备注" width="100" prop="beiZhu">
                    <el-input slot-scope="{row}" v-model="row.beiZhu" size="small" maxlength="64"/>
                </x-row-cell>
            </x-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/tjdgl/TiaoJiaXX";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';
    import {DICT_TYPE_TIAO_JIA_TYPE, DICT_YES_OR_NO} from "@/util/constant";
    import ShangPinXXSelector from "@/view/selector/ShangPinXXAllGuiGeSelector";
    import CaiGouDanXiangQingSelector from "@/view/selector/CaiGouDanXiangQingSelector";
    import RuKuMXSelector from "@/view/selector/RuKuMXSelector";

    export default {
        mixins: [XEditBase],
        components: {ShangPinXXSelector,CaiGouDanXiangQingSelector,RuKuMXSelector},
        data() {
            return {
                tjlx: DICT_TYPE_TIAO_JIA_TYPE,
                beiXiuGaiDingDan:{},
                tiaoJiaXinXiMXsModel :{
                    caiGouDanXiangQingId: "",//采购单详情id
                    tiaoJiaShangPinId: "", // 调价商品Id
                    tiaoJiaShangPinMC: "", // 调价商品名称
                    danWei: "", // 单位
                    guiGe: "", // 规格
                    huoHao: "", // 货号
                    tiaoMa: "", // 条码
                    yuanJinJia: "", // 原进价
                    xianJinJia: "", // 现进价
                    yiRuKuSl: "", // 已入库数量
                    beiZhu: "", // 备注
                }
            }
        },
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                caoZuoRen: [], // 操作人
                caoZuoRenId: [], // 操作人Id
                tiaoJiaLX: [required('string', ['blur', 'change'])], // 调价类型
                tiaoJiaSJ: [required('string', ['blur', 'change'])], // 调价时间
                tiaoJiaShangPinSL: [], // 调价商品数量
                beiXiuGaiDingDan: [required('string', ['blur', 'change'])], // 被修改订单
                beiZhu: [], // 备注
            }
            this.titlePrefix = '调价信息';
            this.defaultForm = {
                id: null,
                caoZuoRen: this.$store.getters.user.name, // 操作人
                caoZuoRenId: this.$store.getters.user.id, // 操作人Id
                tiaoJiaLX: "", // 调价类型
                tiaoJiaSJ: "", // 调价时间
                tiaoJiaShangPinSL: "", // 调价商品数量
                beiXiuGaiDingDan: "", // 被修改订单
                beiZhu: "", // 备注
                tiaoJiaXinXiMXs: [],
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        },
        computed:{
            isChuShiRuKu(){
                return this.form.tiaoJiaLX===this.tjlx.CSHRK.key
            }
        },
        methods:{
            async save(callback) { // 通用save方法 增加回调
                this.loading = true;
                let isvalidate = false;
                try {
                    await this.$refs.form.validate((valid, message) => {
                        if (valid) { // 如果校验通过则提交
                            isvalidate = true;
                        } else { // 校验不通过
                            this.$message.error("有必填项没有填写请检查！");
                            this.loading = false;
                        }
                    });
                } catch (e) {
                    this.loading = false;
                    return;
                }
                if(!this.form.tiaoJiaXinXiMXs || this.form.tiaoJiaXinXiMXs.length<=0){
                    this.$message.error("请选择调价商品！");
                    this.loading = false;
                    return;
                }
                if (!isvalidate) return;
                let api = this.isUpdate ? this.updateService : this.addService;
                const response = await api(this.form).finally(() => {
                    this.loading = false; //TODO 更好的处理保存按钮加载问题
                });//执行保存方法
                if (callback && typeof callback === 'function') {
                    callback(response);
                }
                //打印提示信息
                this.$message.success(response.msg);
                this.$emit("submitted", response);
                this.cancel();
            },
            handleClSelect(cls) {
                let cc = [];
                this.form.tiaoJiaXinXiMXs.forEach(item=>cc.push(item.id));
                cls.forEach(cl => {
                    let obj = {};
                    if(!cc.includes(cl.id)){
                        obj.tiaoJiaShangPinId = cl.id;
                        obj.tiaoJiaShangPinMC = cl.shangPinMC|| '';
                        obj.yuanJinJia = cl.shangPinJinJia|| '';
                        if (cl.shiFuDuoGuiGe===DICT_YES_OR_NO.YES.key)
                            obj.yuanJinJia = cl.guiGeShangPinJinJia|| '';
                        obj.danWei = cl.shangPinDW || '';
                        obj.guiGe = cl.dingJiGuiGe || '';
                        obj.tiaoMa = cl.shangPinTiaoMa || '';
                        obj.huoHao = cl.shangPinHuoHao || '';
                        obj.yiRuKuSL = ''
                        this.form.tiaoJiaXinXiMXs.push(obj);
                    }

                });
            },
            handleClSelectCG(cls) {
                let cc = [];
                this.form.tiaoJiaXinXiMXs.forEach(item=>cc.push(item.shangPinID));
                cls.forEach(cl => {
                    let obj = {};
                    if(!cc.includes(cl.shangPinID)){
                        obj.caiGouDanXiangQingId = cl.id|| '';
                        obj.tiaoJiaShangPinId = cl.shangPinID|| '';
                        obj.tiaoJiaShangPinMC = cl.shangPinMC|| '';
                        obj.yuanJinJia = cl.jinHuoJia|| '';
                        obj.danWei = cl.danWei|| '';
                        obj.guiGe = cl.guiGe || '';
                        obj.tiaoMa = cl.tiaoMa || '';
                        obj.huoHao = cl.huoHao || '';
                        obj.yiRuKuSL = cl.yiRuKuSL || '';
                        this.form.tiaoJiaXinXiMXs.push(obj);
                    }

                });
            },
            handleClSelectRK(cls) {
                let cc = [];
                this.form.tiaoJiaXinXiMXs.forEach(item=>cc.push(item.shangPinID));
                cls.forEach(cl => {
                    let obj = {};
                    if(!cc.includes(cl.shangPinID)){
                        obj.ruKuDanXiangQingId = cl.id|| '';
                        obj.tiaoJiaShangPinId = cl.shangPinID|| '';
                        obj.tiaoJiaShangPinMC = cl.shangPinMC|| '';
                        obj.yuanJinJia = cl.ruKuJia|| '';
                        obj.danWei = cl.danWei|| '';
                        obj.guiGe = cl.guiGe || '';
                        obj.tiaoMa = cl.tiaoMa || '';
                        obj.huoHao = cl.huoHao || '';
                        obj.yiRuKuSL = cl.ruKuSL || '';
                        this.form.tiaoJiaXinXiMXs.push(obj);
                    }

                });
            },
        }
    }
</script>

<style scoped>

</style>
