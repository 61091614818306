<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
                destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="caoZuoRen" label="操作人" />
            <x-detail-item prop="caoZuoRenId" label="操作人Id" />
            <x-detail-item prop="tiaoJiaLX" label="调价类型" >
                <x-dict-show :code="entity.tiaoJiaLX" dictType="T_TIAO_JIA_L_X"/>
            </x-detail-item>
            <x-detail-item prop="tiaoJiaSJ" label="调价时间" />
            <x-detail-item prop="tiaoJiaShangPinSL" label="调价商品数量" />
            <x-detail-item prop="beiXiuGaiDingDan" label="被修改订单" />
            <x-detail-item prop="beiZhu" label="备注" />
        </x-detail>
        <x-divider title="调价信息明细"></x-divider>
        <el-table :data="entity.tiaoJiaXinXiMXs" border class="x-detail-table">
            <el-table-column width="60" label="序号" prop="sort" />
            <el-table-column width="150" prop="tiaoJiaShangPinId" label="调价商品Id" />
            <el-table-column width="150" prop="tiaoJiaShangPinMC" label="调价商品名称" />
            <el-table-column width="150" prop="danWei" label="单位" />
            <el-table-column width="150" prop="guiGe" label="规格" />
            <el-table-column width="150" prop="huoHao" label="货号" />
            <el-table-column width="150" prop="tiaoMa" label="条码" />
            <el-table-column width="150" prop="yuanJinJia" label="原进价" />
            <el-table-column width="150" prop="xianJinJia" label="现进价" />
            <el-table-column width="150" prop="beiZhu" label="备注" />
        </el-table>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/tjdgl/TiaoJiaXX";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                caoZuoRen: "", // 操作人
                caoZuoRenId: "", // 操作人Id
                tiaoJiaLX: "", // 调价类型
                tiaoJiaSJ: "", // 调价时间
                tiaoJiaShangPinSL: "", // 调价商品数量
                beiXiuGaiDingDan: "", // 被修改订单
                beiZhu: "", // 备注
                tiaoJiaXinXiMXs: [],
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>