<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="调价类型" >
                <x-selector-one v-model="search.tiaoJiaLX" size="small" dictType="T_TIAO_JIA_LX"/>
            </x-search-item>
            <x-search-item label="调价时间" textWidth="200px" hasEnd>
                <el-date-picker v-model="search.tiaoJiaSJBegin" size="small" type="datetime" />
                <el-date-picker v-model="search.tiaoJiaSJEnd" size="small" type="datetime" slot="end"/>
            </x-search-item>
            <x-search-item label="被修改订单" textWidth="200px">
                <el-input v-model="search.beiXiuGaiDingDan" size="small" />
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 批量按钮区 -->
        <div class="x-page-btn">
            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>
<!--            <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDeleteBatch">-->
<!--                <el-button type="danger" size="mini" round slot="reference">批量删除</el-button>-->
<!--            </el-popconfirm>-->
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40" fixed/>
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="180" prop="caoZuoRen" label="操作人" />
            <el-table-column width="180" prop="caoZuoRenId" label="操作人Id" />
            <el-table-column width="180" prop="tiaoJiaLX" label="调价类型" >
                <x-dict-show slot-scope="{row}" :code="row.tiaoJiaLX" dictType="T_TIAO_JIA_L_X" />
            </el-table-column>
            <el-table-column width="180" prop="tiaoJiaSJ" label="调价时间" />
            <el-table-column width="180" prop="tiaoJiaShangPinSL" label="调价商品数量" />
            <el-table-column width="180" prop="beiXiuGaiDingDan" label="被修改订单" />
            <el-table-column width="180" prop="beiZhu" label="备注" />
            <el-table-column width="250" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="success" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <!--<detail :detail="detail"/>-->
    </div>
</template>
<script>
    import * as service from "@/service/tjdgl/TiaoJiaXX";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/tjdgl/TiaoJiaXXEdit";
    import Detail from "@/view/tjdgl/TiaoJiaXXDetail";

    export default {
        name: "TiaoJiaXXList",
        mixins: [XTableBase],
        components: {Edit, Detail},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                search: {
                    tiaoJiaLX: '',
                    tiaoJiaSJBegin: '',
                    tiaoJiaSJEnd: '',
                    beiXiuGaiDingDan: '',
                }
            }
        }
    }
</script>

<style scoped>
</style>
