import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/tiao-jia-xx/list',
        method: 'post',
        data
    })
}

//添加保存
export function add(data) {
    return request({
        url: '/tiao-jia-xx/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/tiao-jia-xx/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/tiao-jia-xx/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/tiao-jia-xx/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/tiao-jia-xx/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/tiao-jia-xx/delete-batch',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'tiao-jia-xx-list',
    component: () => import('@/view/tjdgl/TiaoJiaXXList'),
    name: 'TiaoJiaXXList',
    meta: {title: '调价信息', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"调价信息基础列表","methodUrl":"/tiao-jia-xx/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"调价信息添加保存","methodUrl":"/tiao-jia-xx/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"调价信息获取编辑数据","methodUrl":"/tiao-jia-xx/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"调价信息获取详情数据","methodUrl":"/tiao-jia-xx/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"调价信息编辑保存","methodUrl":"/tiao-jia-xx/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"调价信息删除","methodUrl":"/tiao-jia-xx/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"调价信息批量删除","methodUrl":"/tiao-jia-xx/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
*/
